import React, { useState } from "react";
import { Link, Route } from "react-router-dom";
import "./cover.jsx";
import Login from "./Login";
import Logout from "./Logout";
import "./navigation.css";
//import { auth, database } from "./firebase";
import "bootstrap/dist/css/bootstrap.css";
import { FirebaseAuth, useForAuthorisation } from "./context";

// it takes in multiple props which are destructured - it comes from the various components - to set the navbar name colors

// all these props can be removed. in the other components use effect is already setting the nav name to the color when the
// component renders/paints...these clicks may not render?

//let initialrender = true;
function Navigation({
  savedIcon,
  //  savedIcon1,
  stylingOnClickTB,
  stylingOnClickLedger,
  // stylingOnClickNotes,
  stylingOnClickSoCE,
  stylingOnClickTBCurrent,
  stylingOnClickCashflow,
  stylingOnClickJournals,
  stylingOnClickInfo,
  stylingOnClickInvoices,
  stylingOnClickSoCI,
  stylingOnClickSoFP,
  stylingOnClickTax,
  stylingOnClickPdfDisplay,
  stylingOnClickSupport,
  colorNotesMenu,
}) {
  // const [newData, setNewData] = useState([]);
  // const [activeState, setActiveState] = useState();
  // const [stylingOnClickTB, setStylingOnClickTB] = useState();
  const [, setStylingOnClickInfo] = useState();
  const [, setStylingOnClickTBCurrent] = useState();
  const [, setStylingOnClickCover] = useState();
  const [, setStylingOnClickSoCI] = useState();
  const [, setStylingOnClickSoFP] = useState();
  const [, setStylingOnClickSoCE] = useState();
  const [, setStylingOnClickCashflow] = useState();
  const [, setStylingOnClickJournals] = useState();
  const [, setStylingOnClickTax] = useState();
  const [, setStylingOnClickPdfDisplay] = useState();
  const [, setStylingOnClickSupport] = useState();
  // const [loading, setLoading] = useState(false);
  const { emailDetails } = useForAuthorisation();
  //check if there exists a company , if not nav links should not be enabled.
  // let MainUserUID = auth.currentUser.uid;
  //const [linksdisabledboolean, setlinksdisabledboolean] = useState([]);
  // useEffect(() => {
  //   if (initialrender) {
  //     initialrender = false;
  //     return;
  //   }
  //   const urlDb = "/UserSelections/" + MainUserUID;
  //   const newUrlDb = database.ref(urlDb);

  //   newUrlDb.on("value", (snapshot) => {    setLoading(true);
  //     const accounts = snapshot.val();

  //     let newAccounts = [];

  //     newAccounts.push({ ...accounts });

  //     setNewData(newAccounts);

  //    setLoading(false); });

  //   return () => newUrlDb.off();
  // }, [MainUserUID]);
  //console.log(newData);
  // const [linksdisabled, setLinksDisabled] = useState(false);
  //is user hasnt added a company all links should redirect to info page, or alternatively we can set up a demo company but
  //its not setup yet. if users go to links it should work, however errors may result due to less optimizations for no company
  //added
  //
  // const linksdisabledboolean =
  //   newData.map((el1) => Object.keys(el1).length).toString() == 0
  //     ? true
  //     : false;
  //use useEffect?

  // useEffect(() => {
  //   if (initialrender) {
  //     initialrender = false;
  //     return;
  //   }
  //   setLoading(true);

  //   setlinksdisabledboolean(
  //     newData.map((el1) => Object.keys(el1).length).toString() == 0
  //       ? true
  //       : false
  //   );
  //   setLoading(false);
  // }, [newData]);

  // console.log(linksdisabledboolean);
  // if (loading) {
  //   return (
  //     <div
  //       // style={{ marginRight: 115, marginTop: 10 }}
  //       className="spinner-border text-primary"
  //       role="status"
  //     ></div>
  //   );
  // }

  return (
    <FirebaseAuth>
      <div style={{ width: "100%" }}>
        <nav
          style={{
            width: "100%",
            backgroundImage: "linear-gradient(45deg, #bdf3ff, #eefff7)",
          }}
          className="navbar navbar-expand-lg navbar-light bg-light"
        >
          {savedIcon === "Saving..." ? (
            <>
              <h1 style={{ fontSize: 30 }}>
                FilingSta
                <h1
                  style={{ fontSize: 15 }}
                  className="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only"></span>
                </h1>
                k
              </h1>{" "}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </>
          ) : (
            <>
              {" "}
              <Link to="/">
                {" "}
                <h1 style={{ fontSize: 30, color: "black" }}>
                  FilingStack{" "}
                </h1>{" "}
              </Link>{" "}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </>
          )}
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul style={{ textAlign: "left" }} className="navbar-nav mr-auto">
              <Link className="nav-link active" to="/AfterLoginPage">
                {" "}
                <li
                  className="nav-item"
                  style={{ color: stylingOnClickInfo }}
                  onClick={() => {
                    //  console.log(e);
                    setStylingOnClickInfo("MediumBlue");
                  }}
                >
                  Info
                </li>{" "}
              </Link>
              <Link className="nav-link active" to={"/InvoicesPage"}>
                <li
                  style={{ color: stylingOnClickInvoices }}
                  onClick={() => setStylingOnClickCover("MediumBlue")}
                >
                  Invoices
                </li>
              </Link>
              <Link className="nav-link active" to={"/Accounts"}>
                <li
                  style={{ color: stylingOnClickTBCurrent }}
                  onClick={() => setStylingOnClickTBCurrent("MediumBlue")}
                >
                  {" "}
                  Accounts{" "}
                </li>
              </Link>

              <Link className="nav-link active" to={"/TBalance"}>
                <li style={{ color: stylingOnClickTB }}>TB</li>
              </Link>
              <Link className="nav-link active" to={"/Ledger"}>
                <li style={{ color: stylingOnClickLedger }}>Ledger</li>
              </Link>

              <Link className="nav-link active" to={"/soCI"}>
                <li
                  style={{ color: stylingOnClickSoCI }}
                  onClick={() => setStylingOnClickSoCI("MediumBlue")}
                >
                  SoCI
                </li>
              </Link>
              <Link className="nav-link active" to={"/soFP"}>
                <li
                  style={{ color: stylingOnClickSoFP }}
                  onClick={() => setStylingOnClickSoFP("MediumBlue")}
                >
                  SoFP
                </li>
              </Link>
              <Link className="nav-link active" to={"/soCE"}>
                <li
                  style={{ color: stylingOnClickSoCE }}
                  onClick={() => setStylingOnClickSoCE("MediumBlue")}
                >
                  SoCE
                </li>
              </Link>
              <Link className="nav-link active" to={"/NotesMenuPage"}>
                <li style={{ color: colorNotesMenu }}>Notes</li>
              </Link>
              <Link className="nav-link active" to={"/cashflow"}>
                <li
                  style={{ color: stylingOnClickCashflow }}
                  onClick={() => setStylingOnClickCashflow("MediumBlue")}
                >
                  Cashflow
                </li>
              </Link>
              <Link className="nav-link active" to={"/journals"}>
                <li
                  style={{ color: stylingOnClickJournals }}
                  onClick={() => setStylingOnClickJournals("MediumBlue")}
                >
                  Journals
                </li>
              </Link>
              <Link className="nav-link active" to={"/ProvTax"}>
                <li
                  style={{ color: stylingOnClickTax }}
                  onClick={() => setStylingOnClickTax("MediumBlue")}
                >
                  Tax
                </li>
              </Link>
              <Link className="nav-link active" to={"/PdfDisplay"}>
                <li
                  style={{ color: stylingOnClickPdfDisplay }}
                  onClick={() => setStylingOnClickPdfDisplay("MediumBlue")}
                >
                  Afs
                </li>
              </Link>
              <Link className="nav-link active" to="/Support">
                <li
                  style={{ color: stylingOnClickSupport }}
                  onClick={() => setStylingOnClickSupport("MediumBlue")}
                >
                  | Support
                </li>
              </Link>
            </ul>
            <span>
              <Link target="_blank" rel="noreferrer" to="./UsageInfo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 10, marginBottom: 5 }}
                  width="23"
                  height="22"
                  fill="currentColor"
                  class="bi bi-file-text"
                  viewBox="0 0 16 16"
                  title="Documentation"
                  color="#17a2b8"
                >
                  <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                </svg>
              </Link>
            </span>
            <span>
              <Link
                to="./UserProfile"
                style={{ fontSize: 19, marginRight: -5 }}
                className="badge badge-pill badge-info"
              >
                {emailDetails.slice(0, 1)}
              </Link>
              &nbsp;&nbsp;
              <label htmlFor="saved">{savedIcon}</label>
            </span>
            <div className="space" />
            {savedIcon === "Saving..." ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              <svg
                id="saved"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check2-all"
                viewBox="0 0 16 16"
              >
                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7l-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                <path d="M5.354 7.146l.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
              </svg>
            )}
            <span
            //className="d-flex"
            >
              <Logout />
              <Route exact path="/Login" component={Login} />
            </span>
            {/* <Link to="/Login">Logout</Link> */}
          </div>{" "}
          {/* </div> */}
        </nav>
      </div>
    </FirebaseAuth>
  );
}

export default Navigation;
